<template>
  <v-row justify-center mt-12 style="justify-content: space-evenly">
    <v-col
      cols="6"
      v-for="tool in availableTool"
      v-bind:key="tool.name"
      xs2
    >
      <v-row class="d-flex justify-center">
        <v-btn large color="primary" v-bind:name="dsp"
               @click="$changeRoute({ name: 'keystoneTool', params: {dsp: dsp, tool:tool.name }})">
          {{ tool.displayName }}
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { keystoneTools } from '../../../config/keystoneTools'

export default {
  name: 'SelectTool',
  props: ['dsp'],
  data: function () {
    return {
      tools: keystoneTools
    }
  },
  computed: {
    // a computed getter
    availableTool: function () {
      if (this.dsp in this.tools) {
        return this.tools[this.dsp]
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>
